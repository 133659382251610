import React from "react";

const TeamContent = [
  {
    name: "Dave Lim",
    initials: "Dv",
    designation: "Senior Tutor",
    number: "1",
    animationDelay: "200",
    dept: "Math H.O.D",
    desc: "A NTU Nanyang Scholar & 1st class honours with over 15 years of experience teaching at various schools including NYJC, Dave is well liked by his students for his engaging and enlightening lessons. He is featured on various magazines such as \"The Excelligent\"."
  },
  {
    name: "Johnathan Chan",
    initials: "Jc",
    designation: "Senior Tutor",
    number: "2",
    animationDelay: "250",
    dept: "Science H.O.D",
    desc: "Johnathan is a Nanyang President Research Scholar who has taught at NYGHS, DHS and other institutions. He has over 15 years of teaching experience and has assisted numerous students in achieving excellence."
  },
  {
    name: "Yu Lao Shi",
    initials: "Yu",
    designation: "Senior Tutor",
    number: "3",
    animationDelay: "300",
    dept: "Chinese H.O.D",
    desc: "Yu has almost 30 years of teaching experience. She is a responsible and goal-oriented teacher who employs a variety of teaching strategies to meet students' different learning needs and to increase their Chinese language proficiency."
  },
  {
    name: "Chloe",
    initials: "Cl",
    designation: "Senior Tutor",
    number: "4",
    animationDelay: "350",
    dept: "Primary Level Head",
    desc: "An experienced head teacher with more than 8 years in primary level education, specializing in leadership, holistic student growth, and innovative teaching, enhanced by past experience teaching at the prestigious Dimensions International College."
  },
  {
    name: "Gerald Tay",
    initials: "Gt",
    designation: "Senior Tutor",
    number: "5",
    animationDelay: "400",
    dept: "EMS",
    desc: "In his classes, Gerald focuses on explaining concepts in a clear and concise manner and challenges students to think deeper about what they have learnt. He enjoys teaching and gets along well with his students."
  },
  {
    name: "Frances",
    initials: "Fr",
    designation: "Senior Tutor",
    number: "6",
    animationDelay: "450",
    dept: "English Department",
    desc: "Frances is skilled in adapting her teaching style to cater to the different abilities of students and makes English relevant and interesting to her students."
  },
  {
    name: "Han Lao Shi",
    initials: "Hl",
    designation: "Senior Tutor",
    number: "7",
    animationDelay: "500",
    dept: "Chinese Department",
    desc: "An experienced head teacher with more than 8 years in primary level education, specializing in leadership, holistic student growth, and innovative teaching, enhanced by past experience teaching at the prestigious Dimensions International College."
  },
  {
    name: "Yi Jiang",
    initials: "Yj",
    designation: "Junior Tutor",
    number: "8",
    animationDelay: "550",
    dept: "Secondary Level Head",
    desc: "As our latest addition to the team, Yi Jiang is our secondary school level head. He graduated with a bachelor's degree in engineering from NTU. And he contributes fresh energy, advanced analytical capabilities, and a structured approach, cultivating a tailored and professional learning environment that drives student success."
  },
  {
    name: "Navin",
    initials: "Na",
    designation: "Junior Tutor",
    number: "9",
    animationDelay: "600",
    dept: "Science Department",
    desc: "With more than 6 years of teaching experience, Navin adopts an integrated approach in explaining science concepts and provides students with authentic learning experiences."
  },
  {
    name: "Aleen",
    initials: "Al",
    designation: "Senior Executive",
    number: "10",
    animationDelay: "650",
    dept: "Administrative Department",
    desc: "With more than 10 years of service, Aleen ensures efficient operations through strong organisational skills, professionalism, and adaptability."
  },
  {
    name: "Feng Yun",
    initials: "Fy",
    designation: "Senior Executive",
    number: "11",
    animationDelay: "700",
    dept: "Administrative Department",
    desc: "With more than 10 years in the tuition industry, Feng Yun’s experience fosters effective communication, seamless workflows, and proactive problem-solving."
  },
  {
    name: "Judy",
    initials: "Ju",
    designation: "Junior Executive",
    number: "12",
    animationDelay: "750",
    dept: "Marketing Department",
    desc: "A junior executive who brings fresh ideas, supports campaigns, analyses trends, and ensures smooth execution of strategies, contributing enthusiasm and adaptability to drive our team’s marketing goals and growth."
  },
  {
    name: "Patricia",
    initials: "Pa",
    designation: "Junior Executive",
    number: "13",
    animationDelay: "800",
    dept: "Administrative Department",
    desc: "Plays a vital role in managing schedules, coordinating with staff and students, and building customer relationships with professionalism and precision."
  },
  {
    name: "Prisillia",
    initials: "Pr",
    designation: "Junior Executive",
    number: "14",
    animationDelay: "850",
    dept: "Administrative Department",
    desc: "As the newest and youngest member of the team, her efficient, detail-oriented, and proactive approach ensures everything at our centres run smoothly."
  },
  // {
  //   name: "David",
  //   initials: "D",
  //   designation: "Senior Tutor",
  //   number: "4",
  //   animationDelay: "350",
  //   dept: "Economics H.O.D",
  //   desc: "David has had several years of teaching experience in the private education sector. He actively listens to students' questions and makes complicated concepts easier for them to grasp."
  // },
  // {
  //   name: "Danny Wong",
  //   initials: "Da",
  //   designation: "Senior Tutor",
  //   number: "5",
  //   animationDelay: "400",
  //   dept: "English H.O.D",
  //   desc: "With more than 25 years of teaching experience, Danny is a firm believer in communication, his lessons are designed based on his students’ strengths and weaknesses to help them better grasp math concepts."
  // },
  // {
  //   name: "Dorothy",
  //   initials: "Do",
  //   designation: "Senior Tutor",
  //   number: "6",
  //   animationDelay: "450",
  //   dept: "English Department",
  //   desc: "Dorothy has more than 15 years of teaching experience at MOE schools. She is a dedicated educator who believes in instilling in her students the pleasure of learning."
  // },
  // {
  //   name: "Cao Yu Bo",
  //   initials: "Yb",
  //   designation: "Senior Tutor",
  //   number: "7",
  //   animationDelay: "500",
  //   dept: "Science Department",
  //   desc: "Yu Bo has been a full time private tutor for over a decade. He seeks to bring out the best in his students and is a highly motivated and results-oriented teacher."
  // },
  // {
  //   name: "Shilah",
  //   initials: "S",
  //   designation: "Senior Tutor",
  //   number: "8",
  //   animationDelay: "550",
  //   dept: "English Department",
  //   desc: "Ms Shilah is a dynamic and versatile teacher with 6 years of experience in government schools. She is a patient and caring teacher who will go the extra mile to meet individual learning needs."
  // },
  // {
  //   name: "Kenneth",
  //   initials: "K",
  //   designation: "Senior Tutor",
  //   number: "9",
  //   animationDelay: "600",
  //   dept: "Science Department",
  //   desc: "Kenneth has been teaching secondary school science for more than four years. To assist students learn new concepts, bridge learning gaps, he uses engaging and interesting learning materials in his classes."
  // },
  // {
  //   name: "Tumini",
  //   initials: "T",
  //   designation: "Senior Tutor",
  //   number: "10",
  //   animationDelay: "650",
  //   dept: "English Department",
  //   desc: "Tumini, an experienced educator known for her effective teaching methods and rapport with students, is also currently teaching in a school."
  // },
  // {
  //   name: "Christopher",
  //   initials: "C",
  //   designation: "Senior Tutor",
  //   number: "11",
  //   animationDelay: "700",
  //   dept: "Math Department",
  //   desc: "To develop their attitude towards continuous positive learning, Chistopher helps students acquire confidence in tackling tough questions, as well as see patterns and links to examination questions."
  // },
  // {
  //   name: "Yan Yu",
  //   initials: "Yy",
  //   designation: "Senior Tutor",
  //   number: "12",
  //   animationDelay: "750",
  //   dept: "Math Department",
  //   desc: "Yan Yu frequently devises creative ways to reinforce his student’s learning and grasp of topics and encourages his students to take the initiative to clarify doubts and ask questions both during and after classes."
  // },
  // {
  //   name: "Jency",
  //   initials: "J",
  //   designation: "Senior Tutor",
  //   number: "13",
  //   animationDelay: "800",
  //   dept: "English Department",
  //   desc: "Experienced and knowledgeable, Jency never fails to go beyond the call of duty to assist students, and her positive work attitude is deeply appreciated by students and parents alike."
  // },

  // {
  //   name: "Jackie",
  //   initials: "Ja",
  //   designation: "Senior Tutor",
  //   number: "15",
  //   animationDelay: "900",
  //   dept: "Science Department",
  //   desc: "Jackie focuses on helping students understand the root of concepts and navigate phrasing differences, enabling them to arrive at the correct answer and avoid common mistakes."
  // },
  // {
  //   name: "Wydia",
  //   initials: "Wy",
  //   designation: "Senior Tutor",
  //   number: "16",
  //   animationDelay: "950",
  //   dept: "Math Department",
  //   desc: "Driven by the belief that every student can learn, Wydia has been teaching with passion and patience for more than 6 years. Her clear explanations and amiable disposition make her an instant success with her pupils."
  // },

  // {
  //   name: "Alam",
  //   initials: "A",
  //   designation: "Senior Tutor",
  //   number: "18",
  //   animationDelay: "1050",
  //   dept: "English Department",
  //   desc: "With over 10 years of teaching experience at various top government, Alam results-oriented pedagogy has enabled many students to develop an excellent command of the English language and achieve stellar grades."
  // },
  // {
  //   name: "June",
  //   initials: "Ju",
  //   designation: "Senior Tutor",
  //   number: "19",
  //   animationDelay: "1100",
  //   dept: "English & Math Department",
  //   desc: "June specialises in helping students develop their writing skills, and in organising English Language material in a convenient way for students to remember and apply during exams."
  // },
  // {
  //   name: "Kelvin Toh",
  //   initials: "Kt",
  //   designation: "Senior Tutor",
  //   number: "20",
  //   animationDelay: "1150",
  //   dept: "Math Department",
  //   desc: "In Kelvin's classes, he focuses on explaining mathematical concepts in a clear and concise manner and challenges students to think deeper about what they have learnt. He enjoys teaching and gets along well with his students."
  // },

  // {
  //   name: "Min Hui",
  //   initials: "Mh",
  //   designation: "Junior Tutor",
  //   number: "22",
  //   animationDelay: "1250",
  //   dept: "English Department",
  //   desc: "Teaching with love and passion, Min Hui firmly believes that no students should be left behind."
  // },
  // {
  //   name: "Liang Xun Tan",
  //   initials: "Xt",
  //   designation: "Junior Tutor",
  //   number: "23",
  //   animationDelay: "1300",
  //   dept: "Science Department",
  //   desc: "Liang strongly believes in providing a warm and supportive learning environment for his students to develop."
  // },
  // {
  //   name: "Mohnish Ruben",
  //   initials: "R",
  //   designation: "Junior Tutor",
  //   number: "24",
  //   animationDelay: "1350",
  //   dept: "Science Department",
  //   desc: "Ruben believes that understanding the subjects is better than just plain memorizing, he sparks students' curiosity and interest."
  // },

  // {
  //   name: "Jodi",
  //   initials: "A",
  //   designation: "Admin",
  //   number: "26",
  //   animationDelay: "1450",
  //   dept: "",
  //   desc: ""
  // },



];

const TeamFive = () => {
  return (
    <>
      {TeamContent.sort((a, b) => a.number - b.number).map((item, i) => (
        <div
          className="col-lg-4 col-sm-4"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={item.animationDelay}
          key={i}
        >
          <div className="team-wrapper">
            <div className="team-member">
              <div className="img-holder">
                <img src={`images/site/about/team/${item.name}.webp`} alt={item.name}/>
              </div>
              <h6 className="name">{item.name}</h6>
              <div className="position">{item.designation}</div>
            </div>
            <div className="team-desc">
              <div className="dept">{item.dept}</div>
              <div className="position">{item.desc}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TeamFive;
